<template>
  <div class="wrapper layout-wrapper h-100 hide-vertical-scroll" :style="layoutBg">
    <ClientOnly>
      <nav-bar class="fixed-mobile" />
    </ClientOnly>
    <div class="centered-content-layout">
      <div class="main flex-grow-1 d-flex position-relative justify-content-between set-margin">
        <ClientOnly>
          <MainBar />
        </ClientOnly>

        <div class="layout-scroll-area d-flex hide-vertical-scroll chat-scroll-area">
          <div class="layout position-relative chat-page hide-vertical-scroll">
            <div id="main-content" class="d-flex flex-column flex-grow-1 color-changed h-100">
              <slot class="router" />
            </div>
          </div>
          <ClientOnly>
            <div class="right-placeholder" :style="creationsVisible ? '' : 'max-width:100px'">
              <CreationsBar :isVisible="creationsVisible" @toggle="toggleCreations" />
            </div>
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavBar from '@/shared/components/nav-bar/NavBar.vue';
import CreationsBar from '@/shared/components/CreationsBar.vue';
import MainBar from '@/shared/components/MainBar.vue';
import { mainStore } from '@/shared/pinia-store/main';
import { authStore } from '@/shared/pinia-store/auth';

const { dark: isDark } = mainStore();
const { creationsVisible, updateCreationsToggleVisibility } = authStore();
const layoutBg = computed(() => {
  return { background: isDark.value ? '#010123' : '#fff' };
});
const extraLargeScreenlayout = computed(() => {
  return creationsVisible.value ? '1050px' : '1268px';
});
const largeScreenLayout = computed(() => {
  return creationsVisible.value ? 'calc(100vw - 540px)' : 'calc(100vw - 350px)';
});
const tabLayout = computed(() => {
  return creationsVisible.value ? 'calc(100vw - 510px)' : 'calc(100vw - 353px)';
});

const toggleCreations = () => {
  if (creationsVisible.value) {
    //localStorage.setItem('creations-visible', '0');
    updateCreationsToggleVisibility(false);
    return;
  }

  if (!creationsVisible.value) {
    //  localStorage.setItem('creations-visible', '1');
    updateCreationsToggleVisibility(true);
    return;
  }
};
</script>

<style scoped lang="sass">
.centered-content-layout
  margin-right: auto !important
  margin-left: auto !important
  max-width: 1586px
  @media(max-width:1590px)
    max-width:1580px

.stylize-layout
  height: 100vh !important
.footer
  margin-top: -100px
  ::v-deep
    .footer-content
      background: #f3f3f3
.right-placeholder
  //width: calc((100vw - 650px) * 0.5)
  height: calc(var(--doc-height) - var(--default-nav-bar-height))
  background: #FFF
  position: sticky
  top: 0
  align-self: flex-start
  overflow-y: hidden
  overflow-x: hidden
.layout-scroll-area
  overflow-x: hidden
  overflow-y: hidden
.chat-scroll-area
  height: var(--doc-height)

#main-content
  justify-content: space-between
  overflow: visible
.wrapper
  max-height: 100%
  overflow: hidden
  display: flex
  flex-direction: column

.dark .wrapper
    background: #010123 !important

.main
  overflow-y: scroll
  margin: 0
.main, .layout, .wrapper
  overflow: visible !important
.layout
  width: 100%
  max-width: v-bind(extraLargeScreenlayout) !important
  min-width: v-bind(extraLargeScreenlayout) !important
  @media(max-width:1622px)
    max-width: v-bind(largeScreenLayout) !important
    min-width: v-bind(largeScreenLayout) !important
  @media(max-width:1280px) and (min-width:1184px)
    max-width: v-bind(tabLayout) !important
    min-width: v-bind(tabLayout) !important
  overflow: visible !important
  &:not(.w-100)
    max-width: v-bind(extraLargeScreenlayout) !important
    min-width: v-bind(extraLargeScreenlayout) !important
    @media(max-width:1622px)
      max-width: v-bind(largeScreenLayout) !important
      min-width: v-bind(largeScreenLayout) !important
    @media(max-width:1280px) and (min-width:1184px)
      max-width: v-bind(tabLayout) !important
      min-width: v-bind(tabLayout) !important
  &.chat-page
    padding-top: 0 !important
  ::v-deep
    .router, .page
      position: relative
    .router
      width: 100%
      max-width: 820px
      margin: auto
      z-index: 11
    .page
      display: flex
      flex-direction: column
      flex-grow: 1
      margin-bottom: auto !important
      overflow: visible !important

@media(min-width:1365px) and (max-width:1460px)
  .stylize-layout
    max-width: 690px
    min-width: 690px
@media(min-width:1024px) and (max-width:1365px)
  .stylize-layout
    max-width: calc(100% - 328px) !important
    min-width: calc(100% - 328px) !important
  .main
    width: 100%
  .layout
    margin: 0 !important

  .fixed-mobile
    position: sticky !important
    top: 0
    z-index: 112
    width: 100%

.color-changed
  background: #c6c8d6 !important
.dark .color-changed
  background: #17074C !important
.customize-not-applied-margin
  margin-top: 64px
  @media(max-width:1365px)
    margin-top: 123px !important
.hide-placeholder
  display: none !important
.set-margin
  margin-top: 0px !important
.hide-vertical-scroll
  overflow-y: hidden !important
  overflow-x: hidden !important
.hide-vertical-bar
  display: none !important
.customize-margin
  margin-top: 63px !important
  @media(max-width: 1365px)  and (min-width:570px)
    margin-top: 243px !important
    margin-bottom: 10px !important
.customize-try-on-margin
  @media(max-width: 1365px) and (min-width:570px)
    margin-top: 265px !important
</style>
